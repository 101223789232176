import Network from '@/services/network'
import { ref } from 'vue'

export default function handleChartAccount() {
    const network = new Network;
    const chartLoading = ref(false)
    const chartCreateLoading = ref(false)
    const chartNgoLoading = ref(false)
    const chartNgoCreateLoading = ref(false)

    const fetchChartAccount = (query) => {
      return network.api('get', '/accounting/chart-of-accounting' + query);
    }

    const fetchHome = () => { //status, voucher, account_type
      return network.api('get', '/get/home');
    }

    const fetchAccountHeadGroup = (query) => {
        return network.api('get', '/account-head-groups' + query);
    }

    const createNewAccount = (data) => {
      return network.api('post', '/account-head-groups', data);
    }

    const updateAccountGroup = (data) => {
      return network.api('post', '/account-head-groups/'+data.id, data.data);
    }
    
    const createNewAccountHead = (data) => {
      return network.api('post', '/account-heads', data);
    }

    const updateAccountHead = (data) => {
      return network.api('post', '/account-heads/'+data.id, data.data);
    }

    const fetchChartAccountNGOAB = (data) => {
        return network.api('get', '/accounting/chart-of-accounting-ngoab?company_id='+data.companyId+'&account_type='+data.type+'&start_date=2022-01-01');
    }

    const createNewAccountNgo = (data) => {
        return network.api('post', '/account-head-groups-ngo', data);
    }

    const updateAccountGroupNgo = (data) => {
        return network.api('post', '/account-head-groups-ngo/'+data.id, data.data);
    }

    const createNewAccountHeadNgo = (data) => {
        return network.api('post', '/account-heads-ngo', data);
    }

    const updateAccountHeadNgo = (data) => {
        return network.api('post', '/account-heads-ngo/'+data.id, data.data);
    }

    return {
        fetchChartAccount,
        fetchHome,
        createNewAccount,
        createNewAccountHead,
        updateAccountGroup,
        updateAccountHead,

        fetchChartAccountNGOAB,
        createNewAccountNgo,
        createNewAccountHeadNgo,
        updateAccountGroupNgo,
        updateAccountHeadNgo,
        fetchAccountHeadGroup,

        chartCreateLoading,
        chartLoading,
        chartNgoLoading,
        chartNgoCreateLoading
    }
}
